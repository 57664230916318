import React from "react";

import Div from "@components/Div";
import { Error } from "@components/Heading";
import { TextMediumWeight, TextUpperCase } from "@components/Text";
import StyledInputText from "@components/StyledInputText";

import { INPUT } from "@utils/constant";
import { addHyphenToNumber, debounce } from "@utils/utils";

import InputPassword from "./InputPassword";
import StyledInputNumber from "@components/StyledInputNumber";

export const InputText = (props) => <CustomInputText {...props} />;

const CustomInputText = (prop) => {
  const {
    formikProps,
    name,
    label,
    type,
    maxLength,
    isPassword,
    isPrice,
    labelAlignment = "center",
    disabled = false,
    isLowercaseLabel = false,
    mt = 3,
  } = prop;

  const {
    errors = {},
    touched = {},
    handleChange,
    handleBlur,
  } = formikProps || {};

  const errorMessage = errors[name] || "";
  const touchedMessage = touched[name] || "";

  const handleKeyPress = (evt) => {
    const event = evt || window.event;
    const { value: eventValue, name: eventName } = event.target;
    const { COMPANY_NUMBER, PERSONAL_NUMBER } = INPUT.NAME;

    if (name === COMPANY_NUMBER || name === PERSONAL_NUMBER) {
      if (handleChange) {
        const hyphenPlace = name === COMPANY_NUMBER ? 6 : 8;
        const value = addHyphenToNumber(eventValue, hyphenPlace);
        handleChange({
          target: {
            name: [eventName],
            value: value,
          },
        });
      }
    }
  };

  const handlePaste = debounce((event) => {
    handleKeyPress(event);
  });

  const renderLabel = () => {
    const LabelWrapper = isLowercaseLabel ? TextMediumWeight : TextUpperCase;

    return (
      <LabelWrapper mb={2} display="block" {...prop}>
        {label}
      </LabelWrapper>
    );
  };

  const handleOnValueChangeForInputNumber = (onChange, event ) => {
    const { value } = event.target;
    onChange({...event, value});
  };

  let inputComponent;

  if (isPassword) {
    inputComponent = (
      <InputPassword
        {...prop}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errorMessage && touchedMessage}
      />
    );
  } else if (isPrice) {
    inputComponent = (
      <StyledInputNumber
        {...prop}
        type={type}
        onValueChange={handleOnValueChangeForInputNumber.bind(
          this,
          handleChange
        )}
        onBlur={handleBlur}
        error={errorMessage && touchedMessage}
        maxLength={maxLength}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
        disabled={disabled}
        slotChar=" "
      />
    );
  } else {
    inputComponent = (
      <StyledInputText
        {...prop}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errorMessage && touchedMessage}
        maxLength={maxLength}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
        disabled={disabled}
      />
    );
  }

  return (
    <>
      {label && (
        <Div width={1} mt={mt} pt={1} textAlign={labelAlignment}>
          {renderLabel()}
        </Div>
      )}
      {inputComponent}
      {errorMessage && touchedMessage && (
        <Div pb={1} pt={3} m={labelAlignment !== "left" && "auto"}>
          <Error>{errorMessage}</Error>
        </Div>
      )}
    </>
  );
};

export default InputText;
