import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import queryString from "query-string";
import { H3, H4 } from "@components/Heading";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import Card from "@components/Card";
import AlertPage from "@pages/auth/shared/AlertPage";
import ProgressSpinner from "@components/ProgressSpinner";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import SurveyForm from "@components/Survey/SurveyForm";
import setPasswordService from "@app/services/auth/setPasswordService";
import SetPasswordConfig from "./SetPasswordConfig.json";
import ErrorMessage from "../../shared/ErrorMessage";
import { ROUTES } from "@utils/constant";

export const SetPassword = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { search = "" } = location;
  const params = queryString.parse(search);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const surveyRef = useRef(null);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSetPassword = async values => {
    setIsFetching(true);
    const { id = "", token = "" } = params;
    const payload = values;
    try {
      await setPasswordService(payload, id, token);
      setIsFetching(false);
      setIsSubmitted(true);
    } catch (e) {
      setIsFetching(false);
      setErrorMessage(messages.link_not_valid);
      throw new Error(e);
    }
  };

  const redirectToLogin = () => {
    history.push(ROUTES.LOGIN.URL);
  };

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleSetPassword(data);
  };

  return (
    <Section
      flex={1}
      display="flex"
      justifyContent="center"
      bg="var(--site-background-color)"
      className="reset-password"
    >
      <Container
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        {isFetching ? (
          <ProgressSpinner />
        ) : (
          <Div
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isSubmitted && (
              <Div>
                <AlertPage
                  sourcePage={messages.title_set_new_password}
                  redirectToLogin={redirectToLogin}
                />
              </Div>
            )}
            {!isSubmitted && (
              <Card width={1} maxWidth="465px">
                <Div
                  px={[3, 3, 4, 4]}
                  py="24px"
                  pb="0"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gridGap={3}
                >
                  <H3
                    color="var(--blue-dark) !important"
                    textTransform="none !important"
                    display={"block"}
                    textAlign="center"
                  >
                    {messages.title_set_new_password}
                  </H3>
                  <H4 textAlign="center" fontSize="var(--fs-link-m) !important">
                    {messages.text_new_password}
                  </H4>
                  <SurveyForm
                    data={{ password: "", confirm_password: "" }}
                    formConfig={SetPasswordConfig}
                    onRefLoaded={handleLoadSurveyRef}
                    onComplete={handleSave}
                    showCompleteButton={true}
                  />
                </Div>
              </Card>
            )}
          </Div>
        )}
        {errorMessage && (
          <Div display="flex" justifyContent="center">
            <ErrorMessage errorMessage={errorMessage} />
          </Div>
        )}
        <GDPRInformationMessage />
      </Container>
    </Section>
  );
};

export default SetPassword;
