import Axios from "@app/api/axios";
import { PERSON_INFORMATION } from "@utils/constant";
import { encodeHTML } from "@utils/utils";
import queryString from "query-string";

const personInformationSearchService = (
  payload,
  customer_id = null,
  watchList = null
) => {
  payload = payload.map(obj =>
    obj.includes("-") ? obj.replaceAll("-", "") : obj
  );
  const queryParams = {
    ssnnumber: encodeHTML(payload, "%"),
    customer_id,
    check_watch_list: watchList,
  };
  const url = `${PERSON_INFORMATION}?${queryString.stringify(queryParams, {
    skipNull: true,
  })}`;
  return Axios.get(url).then(response => response);
};
export default personInformationSearchService;
