import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import customerService from "@app/services/customers/customerService";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import { searchableColumns, FILTER_KEY_FROM, FILTER_KEY_TO } from "./constants";

const useInvoices = () => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      try {
        const { data: { data: fetchedCustomers = [] } = {} } =
          await customerService();
        const formattedCustomers = fetchedCustomers.map(
          ({ customer_name, id }) => ({
            customer_name,
            name: `${customer_name} #${id}`,
            code: id,
          })
        );

        if (JSON.stringify(customers) !== JSON.stringify(formattedCustomers)) {
          setCustomers(formattedCustomers);
        }
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, [messages.exception_error_message, showErrorToast, customers]);

  const hasReadCustomerPermission = hasAnyPermissions([
    AdminPermissions.AdminReadCustomers,
    AdminPermissions.AdminWriteCustomers,
  ]);

  const dataTableConfig = {
    header: {
      title: "subscription_order_invoice",
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
        },
      ],
    },
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_billing_invoices_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: "invoices.csv",
    api: {
      resource: `/invoices`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", ["billingAccount", "billingAccount.customer"]]],
    },
    is_filter_options_updated: isLoading,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[invoice_date][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.title_customers,
        id: "filter[billingAccount.customer_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[billingAccount.customer_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[billingAccount.customer_id]",
            options: customers,
            type: "multiselect",
          },
        ],
      },
    ],
    default_sort: {
      field: "fortnox_id",
      order: "desc",
    },
    columns: [
      {
        title: "invoice",
        db_field: "fortnox_id",
        type: "id",
        width: "80px",
        sortable: true,
        sortField: "fortnox_id",
      },
      {
        title: "type_label",
        db_field: "orders_type",
        width: "70px",
        type: "text",
      },
      {
        title: "created_at",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "label_invoice_date",
        db_field: "invoice_date",
        type: "text",
        sortable: true,
      },
      {
        title: "title_customer",
        db_field: "billing_account.name",
        type: "link",
        href: values => {
          const customerId = values["billing_account.customer_id"] || "";

          return ROUTES.CUSTOMER_DETAILS.URL.replace(":id", customerId);
        },
        className: hasReadCustomerPermission ? "text-blue" : "text-grey",
        sortable: true,
      },
      {
        title: "account",
        db_field: "billing_account.name",
        type: "text",
        sortable: true,
        sortField: "billing_account.name",
      },

      {
        title: "label_fortnox_id",
        db_field: "billing_account.fortnox_customer_id",
        type: "text",
        width: "95px",
        sortable: true,
      },
      {
        title: "placeholder_your_reference_person",
        db_field: "billing_account.your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "billing_account.invoice_remark",
        type: "text",
      },
      {
        title: "amount",
        db_field: "amount",
        type: "currency",
        sortable: true,
      },
    ],
  };

  const headerConfig = {
    title: messages.subscription_order_invoice,
  };

  return {
    dataTableConfig,
    headerConfig,
  };
};

export default useInvoices;
