import React from "react";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

import NumberSchema from "@pages/auth/schema/Numbers";

import { INPUT } from "@utils/constant";
import { INVOICE_TYPE } from "@utils/enum";

export const BasicInformationSchema = Yup.object({
  name: Yup.string().required(
    <FormattedMessage
      id="validation_empty_company_name"
      defaultMessage="Enter company name"
    />,
  ),
  address_1: Yup.string().nullable(),
  zip_code: Yup.number().required(
    <FormattedMessage id="validation_enter_zip_code" />,
  ),
  address_2: Yup.string().nullable(),
  city: Yup.string().required(<FormattedMessage id="validation_enter_city" />),
  country_name: Yup.string().required(
    <FormattedMessage id="validation_enter_country_name" />,
  ),
}).concat(NumberSchema.pick([INPUT.NAME.COMPANY_NUMBER]));

export const InvoiceReferenceSchema = Yup.object({
  invoice_remark: Yup.string().nullable(),
});

export const PaymentSchema = Yup.object({
  invoice_type: Yup.string().required(
    <FormattedMessage
      id="validation_enter_invoice_type"
      defaultMessage="Enter invoice type"
    />,
  ),
  email_invoice: Yup.string().when("invoice_type", ([invoice_type], schema) =>
    invoice_type === INVOICE_TYPE.EMAIL_INVOICE
      ? schema
          .email(
            <FormattedMessage
              id="validation_valid_email_input"
              defaultMessage="Invalid email"
            />,
          )
          .required(
            <FormattedMessage
              id="validation_empty_email_input"
              defaultMessage="Enter email"
            />,
          )
      : schema.nullable(),
  ),
  fortnox_customer_id: Yup.string().nullable(),
});
