import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useToast } from "@hooks/useToast";

import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

export const useCommentsTab = () => {
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();
  const userInfo = useSelector(state => state.authReducer.userInfo);

  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await getCaseDetailsById(case_id);
      const { data: { comments: resComments = [] } = {} } = response;
      setComments(resComments);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  }, [case_id]);

  useEffect(() => {
    fetchData();
  }, [case_id, fetchData]);

  const handleOnChangeComment = event => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleAddComment = async () => {
    const payload = {
      comment: newComment,
      user_id: userInfo?.id,
    };
    try {
      const { data: _case = {} } = await updateCaseDetailsById(
        case_id,
        payload
      );
      setComments(_case?.comments || comments);

      showSuccessToast(messages.label_comment_added);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
    setNewComment("");
  };

  const handleDeleteComment = () => {
    fetchData();
  };

  return {
    comments,
    isFetching,
    newComment,
    handleAddComment,
    handleDeleteComment,
    handleOnChangeComment,
  };
};
