import React from "react";
import { FormattedMessage } from "react-intl";

import Currency from "@components/Currency";

import {
  ADMIN,
  ROUTES,
  SPECIAL_PACKAGE,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import { FORM_INPUT_TYPE, RENEWAL_MODE_TYPE } from "@utils/enum";
import { formatDate } from "@utils/utils";

export const RENEWAL_OPTIONS = [
  {
    label: <FormattedMessage id="on" />,
    value: RENEWAL_MODE_TYPE.ON,
  },
  {
    label: <FormattedMessage id="off" />,
    value: RENEWAL_MODE_TYPE.OFF,
  },
];

export const getCustomerConfig = data => {
  const configuration = {
    details: [
      {
        key: "company_name",
        translationKey: "title_customer",
        type: FORM_INPUT_TYPE.LINK,
        value: data.company_name,
        href: ROUTES.CUSTOMER_DETAILS.URL.replace(":id", data.id),
      },
      {
        key: "registration_nr",
        translationKey: "label_company_number",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.registration_nr,
      },
      {
        key: "name",
        translationKey: "user",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.firstname,
      },
    ],
  };

  return configuration;
};

const handleOnRenewalChange = ({ value }, formik) => {
  const {
    values: { activation_date },
    setFieldValue,
  } = formik;

  setFieldValue("renewals", value);

  if (activation_date && value) {
    const renewalDate = new Date(activation_date);
    renewalDate.setFullYear(renewalDate.getFullYear() + 1);

    setFieldValue("renewal_date", renewalDate);
  }
};

export const getPackageConfig = (
  data,
  packages,
  onStatusChange,
  onActivationDateChange,
  intialData,
) => {
  const packageOptions = packages.map(({ id, name, credits, price }) => ({
    label: name,
    value: id,
    available_credits: credits,
    total_credits: credits,
    price: price,
  }));

  const configuration = {
    packages: [
      {
        key: "product_id",
        translationKey: "package_label",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: Number(data?.product_id),
        fullWidth: true,
        handleChange: (event, formikValues) => {
          const { value } = event;
          const { values, setValues } = formikValues;
          const product =
            packageOptions.find(option => option?.value == value) ?? {};
          const { available_credits, total_credits, price, label } = product;
          const upgrade =
            intialData?.total_credits < total_credits &&
            intialData?.price < price
              ? true
              : false;
          const downgrade =
            intialData?.total_credits > total_credits &&
            intialData?.price > price
              ? true
              : false;
          setValues({
            ...values,
            product_id: value,
            available_credits,
            total_credits,
            price,
            email_notification: upgrade || downgrade ? true : undefined,
            upgrade,
            downgrade,
            name: label,
          });
        },
      },
      {
        key: "credits",
        translationKey: "watchlist_title_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: `${data?.available_credits}/${data?.total_credits}`,
        editable: false,
      },
      {
        key: "total_credits",
        translationKey: "total_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: data?.total_credits,
        isHidden: true,
        fullWidth: true,
        editable: values => {
          const { label = "" } =
            packageOptions.find(({ value }) => value === values?.product_id) ??
            {};

          return label !== SPECIAL_PACKAGE;
        },
        isDisabled: values => {
          const { label = "" } = packageOptions.find(
            o => o?.value === values?.product_id
          );
          return label !== SPECIAL_PACKAGE;
        },
        handleChange: (event, formikValues) => {
          const { value } = event.target;
          const { values, setValues } = formikValues;
          const { price } = values;
          const upgrade =
            intialData?.total_credits < value && intialData?.price < price
              ? true
              : false;
          const downgrade =
            intialData?.total_credits > value && intialData?.price > price
              ? true
              : false;
          setValues({
            ...values,
            total_credits: value,
            upgrade,
            downgrade,
            email_notification: upgrade || downgrade ? true : undefined,
          });
        },
      },
      {
        key: "available_credits",
        translationKey: "text_available_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: data?.available_credits,
        editable: values => {
          const { label = "" } =
            packageOptions.find(({ value }) => value === values?.product_id) ??
            {};
          return !(label === SPECIAL_PACKAGE || (Number(data?.product_id) === Number(values?.product_id)));
        },
        isHidden: true,
        fullWidth: true,
      },
      {
        key: "price_in_currency",
        translationKey: "watchlist_label_price",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: <Currency value={data?.price} />,
        fullWidth: true,
        editable: false,
      },
      {
        key: "price",
        translationKey: "price",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: parseInt(data?.price) ?? 0,
        fullWidth: true,
        isHidden: true,
        editable: true,
        handleChange: (event, formikValues) => {
          const { value } = event.target;
          const { values, setValues } = formikValues;
          const { total_credits } = values;
          const upgrade =
            intialData?.total_credits < total_credits &&
            intialData?.price < value
              ? true
              : false;
          const downgrade =
            intialData?.total_credits > total_credits &&
            intialData?.price > value
              ? true
              : false;
          setValues({
            ...values,
            price: value,
            upgrade,
            downgrade,
            email_notification: upgrade || downgrade ? true : undefined,
          });
        },
      },
      {
        key: "email_notification",
        translationKey: "send_email_confirmation",
        type: FORM_INPUT_TYPE.CHECK_BOX,
        value: data?.email_notification,
        fullWidth: true,
        editable: values => values?.email_notification === undefined,
        isHidden: values => values?.email_notification !== undefined,
        conditionalHeader: true,
        header: values =>
          values?.upgrade
            ? "message_send_upgrade_order_confirmation"
            : values?.downgrade
              ? "message_send_downgrade_order_confirmation"
              : "message_send_order_confirmation",
      },
    ],
    settings: [
      {
        key: "status",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="title_registered" />,
            value: SUBSCRIPTION_STATUS.REGISTERED,
          },
          {
            label: <FormattedMessage id="active" />,
            value: SUBSCRIPTION_STATUS.ACTIVE,
          },
          {
            label: <FormattedMessage id="terminated_label" />,
            value: SUBSCRIPTION_STATUS.TERMINATED,
            hide: data.status === SUBSCRIPTION_STATUS.REGISTERED,
          },
        ],
        selectorField: "label",
        value: data.status,
        fullWidth: true,
        handleChange: onStatusChange,
      },
      {
        key: "activation_date",
        translationKey: "activation_date",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: data.activation_date,
        fullWidth: true,
        isHidden: data?.status !== SUBSCRIPTION_STATUS.ACTIVE,
        isDisabled : values => values?.status === SUBSCRIPTION_STATUS.ACTIVE ? true : false,
        show: values => {
          return (
            values?.status === SUBSCRIPTION_STATUS.ACTIVE ||
            values?.status === SUBSCRIPTION_STATUS.REGISTERED
          );
        },
        handleChange: onActivationDateChange,
        maxDate: values => {
          return values?.status === SUBSCRIPTION_STATUS.ACTIVE
            ? new Date()
            : null;
        },
        minDate: values => {
          return values?.status === SUBSCRIPTION_STATUS.REGISTERED
            ? (() => {
              let tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1);
              return tomorrow;
            })()
            : null;
        },
      },
      {
        key: "renewals",
        translationKey: "renewal",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: RENEWAL_OPTIONS,
        selectorField: "label",
        value: data.renewals,
        fullWidth: true,
        isHidden: data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return values?.status !== SUBSCRIPTION_STATUS.TERMINATED;
        },
        handleChange: handleOnRenewalChange,
      },
      {
        key: "renewal_date",
        translationKey: "renewal_date",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: data.renewal_date,
        fullWidth: true,
        isHidden:
          !data?.renewals || data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return (
            !!values?.renewals &&
            values?.status !== SUBSCRIPTION_STATUS.TERMINATED
          );
        },
        minDate: values => {
          if (!values?.activation_date) {
            return null;
          }

          const minRenewalDate = new Date(values?.activation_date);
          minRenewalDate.setDate(minRenewalDate.getDate() + 1);

          return minRenewalDate;
        },
      },
      {
        key: "email_notification",
        translationKey: "send_email_confirmation",
        type: FORM_INPUT_TYPE.CHECK_BOX,
        value: data?.email_notification,
        fullWidth: true,
        editable: values => values?.email_notification === undefined,
        isHidden: values => values?.email_notification !== undefined,
        header: "message_send_termination_order_confirmation",
      },
    ],
  };
  return configuration;
};

export const buildPayload = (
  activeSubscriptionData,
  customerId,
  adminEmail,
  intialSubscriptionData,
) => {
  const {
    product_id,
    id: subscription_id,
    status,
    price,
    available_credits,
    total_credits,
    email_notification = true,
    renewals,
    renewal_date,
    billing_account_id,
    activation_date,
    name,
  } = activeSubscriptionData;

  let payload = {
    customer_id: customerId,
    product_id,
    subscription_id,
    status,
    signed_by: `${ADMIN} ${adminEmail}`,
    email_notification,
    renewals,
    renewal_date: formatDate(renewal_date),
    billing_account_id: billing_account_id,
    activation_date: formatDate(activation_date),
  };

  if (name === SPECIAL_PACKAGE) {
    payload = {
      ...payload,
      total_credits: Number(total_credits),
      available_credits,
      price: Number(price),
    };
  }
  if (
    intialSubscriptionData?.available_credits !== available_credits &&
    intialSubscriptionData?.product_id === product_id
  ) {
    payload = { ...payload, available_credits };
  }
  if (
    Number(intialSubscriptionData?.price) !== Number(price) &&
    intialSubscriptionData?.product_id === product_id
  ) {
    payload = { ...payload, price: Number(price) };
  }

  return payload;
};
