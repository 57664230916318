import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import Chip from "@components/Chip";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextMediumWeight, TextSemiBoldWeight, TextCapitalize } from "@components/Text";

import EditFormDialog from "./EditFormDialog";

import { FORM_INPUT_TYPE } from "@utils/enum";
import { isAdminUser } from "@utils/utils";
import Link from "@components/Link";
import ProductEditFieldCard from "../pages/admin/services/tabs/ProductDetailEditFieldCard";
import DescriptionEditFieldCard from "../pages/admin/services/tabs/ProductDescriptionEditFieldCard";
import ConfigurationEditFieldCard from "../pages/admin/services/tabs/ProductConfigurationEditFieldCard";

TextMediumWeight.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

Text.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

TextCapitalize.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

const StyledDescription = styled.div`
  font-weight: 300;
  color: var(--grey-dark);

  strong {
    font-weight: var(--semibold-weight);
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;

    li {
      margin-top: 8px;
      margin-left: 24px;
    }
  }
`;

const renderFiller = ({ key, index }) => (
  <Div
    key={`${key}_${index}`}
    flex="1 1 40%"
    display={["none", "none", "block", "block"]}
  />
);

const renderText = ({ key, index, label, value }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {label && `${label}: `}
      <Text>{value}</Text>
    </TextMediumWeight>
  </Div>
);

const renderLink = ({ key, index, label, value, href }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {label && `${label}: `}
      {href ? (
        <a href={href} style={{ textDecoration: "none", color: "inherit" }}>
          <Text>{value}</Text>
        </a>
      ) : (
        <Text>{value}</Text>
      )}
    </TextMediumWeight>
  </Div>
);

const renderDropdown = ({
  label,
  value,
  options,
  optionFields,
  messages,
  selectorField,
}) => {
  const selectedOption = options.find(option => option.value === value);
  const presentedValue = selectedOption?.[selectorField] ?? value;
  const presentedLabel = selectedOption?.label || "";

  return (
    <>
      <Div flex="1 1 40%">
        <TextMediumWeight>
          {`${label}: `}
          <TextCapitalize>{presentedLabel || presentedValue || value}</TextCapitalize>
        </TextMediumWeight>
      </Div>

      {optionFields?.[value]?.map(
        ({ key, value, translationKey, type }, index) => {
          const renderItem = components[type];

          return (
            <Div key={`${key}_${index}`} flex="1 1 40%" mt={[-2, -2, 0, 0]}>
              {renderItem?.({
                key,
                value,
                label: messages[translationKey],
                type,
              })}
            </Div>
          );
        }
      )}
    </>
  );
};

const renderSelectorValue = ({ key, index, label, value, selectorField }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {`${label}: `}
      <Text>{value?.[selectorField]}</Text>
    </TextMediumWeight>
  </Div>
);

const renderEditor = ({ key, index, label, value, fullWidth }) => {
  return (
    <Div key={`${key}_${index}`} flex={`1 1 ${fullWidth ? "100%" : "40%"}`}>
      {label && <TextMediumWeight>{`${label}: `}</TextMediumWeight>}
      <Div mt={2} />
      <StyledDescription dangerouslySetInnerHTML={{ __html: value }} />
    </Div>
  );
};

const renderMultiselect = ({ key, index, label, value }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>{`${label}: `}</TextMediumWeight>
    <Div display="flex" flexDirection="column" gridGap={1}>
      {value?.map(item => (
        <Text key={item?.name}>{`- ${item?.name}`}</Text>
      ))}
    </Div>
  </Div>
);

const renderChip = ({ key, index, label, options }) => {
  return (
    <Div key={`${key}_${index}`} flex="1 1 40%">
      <TextMediumWeight>{`${label}: `}</TextMediumWeight>
      <Div
        display={"flex"}
        flexDirection={["column", "column", "row", "row"]}
        gridGap={3}
        mt={3}
      >
        {options?.map((option, optionIndex) => (
          <Chip
            key={`${key}_${index}_${optionIndex}`}
            label={option.label}
            icon={"icon-pin"}
            removeIcon={"icon-download-assignment"}
            onClick={() => {
              window.open(option?.url, "_blank");
            }}
          />
        ))}
      </Div>
    </Div>
  );
};

const renderHtml = ({ key, index, label, value, fullWidth }) => {
  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      display="flex"
      alignItems="center"
      gridGap={1}
    >
      <TextMediumWeight>{`${label}: `}</TextMediumWeight>

      <Div dangerouslySetInnerHTML={{ __html: value }} />
    </Div>
  );
};

const renderFileUpload = ({ key, index, label, value }) => {
  let displayContent;

  if (value?.file_name || value?.name) {
    if (value?.url) {
      displayContent = (
        <Link href={value.url} target="_blank" rel="noopener noreferrer">
          <Chip
            mt="2"
            key={value?.id}
            width="fit-content"
            label={value?.file_name || value?.name}
            icon={"icon-pin"}
            removeIcon={"icon-download-assignment"}
          />
        </Link>
      );
    }
  } else {
    displayContent = <Text>{value}</Text>;
  }

  return (
    <Div key={`${key}_${index}`} flex="1 1 40%" mt="2">
      <TextMediumWeight>
        {label && `${label}: `}
        {displayContent}
      </TextMediumWeight>
    </Div>
  );
};

const components = {
  [FORM_INPUT_TYPE.FILLER]: renderFiller,
  [FORM_INPUT_TYPE.TEXT]: renderText,
  [FORM_INPUT_TYPE.INPUT_NUMBER]: renderText,
  [FORM_INPUT_TYPE.DROPDOWN]: renderDropdown,
  [FORM_INPUT_TYPE.AUTOCOMPLETE]: renderSelectorValue,
  [FORM_INPUT_TYPE.CALENDAR]: renderText,
  [FORM_INPUT_TYPE.EDITOR]: renderEditor,
  [FORM_INPUT_TYPE.MULTISELECT]: renderMultiselect,
  [FORM_INPUT_TYPE.FILES]: renderChip,
  [FORM_INPUT_TYPE.HTML]: renderHtml,
  [FORM_INPUT_TYPE.FILE_UPLOAD]: renderFileUpload,
  [FORM_INPUT_TYPE.LINK]: renderLink,
};

const EditableFieldsCard = ({
  fullWidthInputs,
  title,
  config,
  validationSchema,
  isEditPermission,
  onSubmit,
  module,
}) => {
  const { messages } = useIntl();

  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);

  const handleEdit = () => {
    setIsEditDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setIsEditDialogVisible(false);
  };

  const renderDialogContent = () => {
    if (module === "product") {
      switch (title) {
        case "Detaljer":
          return (
            <ProductEditFieldCard
              fullWidthInputs={fullWidthInputs}
              title={title}
              config={config}
              onHide={handleCloseDialog}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            />
          );
        case "Produktbeskrivning":
          return (
            <DescriptionEditFieldCard
              fullWidthInputs={fullWidthInputs}
              title={title}
              config={config}
              onHide={handleCloseDialog}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            />
          );
        case "Konfiguration":
          return (
            <ConfigurationEditFieldCard
              fullWidthInputs={fullWidthInputs}
              title={title}
              config={config}
              onHide={handleCloseDialog}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            />
          );
        default:
          return null;
      }
    }
    return (
      <EditFormDialog
        fullWidthInputs={fullWidthInputs}
        title={title}
        config={config}
        onHide={handleCloseDialog}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      />
    );
  };

  return (
    <Card mb={3} p={3} borderRadius="20px" width={1}>
      {isEditDialogVisible && renderDialogContent()}

      <Div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <TextSemiBoldWeight>{title}</TextSemiBoldWeight>
        {isEditPermission && (
          <ButtonLinkIcon
            px={0}
            label={<TextMediumWeight>{messages.label_edit}</TextMediumWeight>}
            onClick={handleEdit}
            icon={
              <Icon
                name="pen"
                mr={2}
                color="var(--turquoise)"
                fontSize="var(--fs-icon-s)"
              />
            }
          />
        )}
      </Div>

      <Div width={1} height="1px" backgroundColor="var(--grey-lightest)" />

      <Div
        mt={3}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {config?.map(
          (
            {
              key,
              translationKey,
              value,
              type,
              options,
              optionFields,
              isAdminField,
              selectorField,
              fullWidth,
              isHidden,
              show,
              maxLength,
              href,
              isReadOnlyField,
            },
            index
          ) => {
            const isVisible =
              typeof isHidden === "function"
                ? !isHidden(value || {})
                : !isHidden;

            if ((isAdminField && !isAdminUser()) || !isVisible) {
              return null;
            }
            const renderItem = components[type];

            return renderItem?.({
              key,
              index,
              label: messages[translationKey] || "",
              value,
              options,
              optionFields,
              messages,
              selectorField,
              fullWidth,
              show,
              maxLength,
              href,
              isReadOnlyField,
            });
          }
        )}
      </Div>
    </Card>
  );
};

EditableFieldsCard.propTypes = {
  fullWidthInputs: PropTypes.bool,
  title: PropTypes.string,
  config: PropTypes.array,
  validationSchema: PropTypes.object,
  isEditPermission: PropTypes.bool,
  onSubmit: PropTypes.func,
  module: PropTypes.string,
};

export default EditableFieldsCard;
