import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonIcon } from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import InputTextArea from "@components/InputTextArea";
import Link, { LinkArrow } from "@components/Link";
import RoundedInputText from "@components/RoundedInputText";
import {
  ColouredSemiBoldText,
  Text,
  ColouredMediumWeight,
} from "@components/Text";

import { trackEventFocus } from "@src/analyticsFunctions";
import Config from "@src/config";

import { ROUTES } from "@utils/constant";
import {
  getPinWithFullYear,
  ValidateSwedishSSN,
  validateSSNForAdultPerson,
} from "@utils/utils";
import { useUser } from "@contexts/AuthProvider";

const checkForAdultPersonWithSSN = (ssn, customerId) => {
  if (!customerId || customerId === Config.AXFOOD_CUSTOMER_ID) {
    return true;
  }

  return validateSSNForAdultPerson(ssn);
};

const SSNSearch = props => {
  const { messages } = useIntl();
  const { isAuthenticated } = useUser();
  const { ssnNumbers = [""] } = props;
  const [isMultiple, setIsMultiple] = useState(ssnNumbers.length > 1);
  const [showMultipleSsnError, setShowMultipleSsnError] = useState([]);

  const handleOnSSNNumberChange = e => {
    setShowMultipleSsnError([]);
    props.onChange(e.target.value.split("\n"));
  };

  const handleMultipleSsnSearch = () => {
    setIsMultiple(true);
  };

  if (ssnNumbers && ssnNumbers.length >= 1) {
    trackEventFocus();
  }

  const handleOnSubmit = () => {
    if (ssnNumbers.length === 0) {
      return;
    }

    const formattedSSNNumbers = ssnNumbers
      .filter(ssn => ssn !== "")
      .map(ssn => (ssn.length < 12 ? getPinWithFullYear(ssn) : ssn));

    const invalidSSNObjects = formattedSSNNumbers
      .filter(objectSSN => !ValidateSwedishSSN(objectSSN))
      .map(objectSSN => ({ ssn: objectSSN, error: messages.error_invalid_id }));
    const invalidAgeSSNObjects = formattedSSNNumbers
      .filter(
        objectSSN => !checkForAdultPersonWithSSN(objectSSN, props.customerId)
      )
      .map(objectSSN => ({
        ssn: objectSSN,
        error: messages.error_person_age_under_18,
      }));
    const validSSNObjects = formattedSSNNumbers.filter(
      objectSSN =>
        ValidateSwedishSSN(objectSSN) &&
        checkForAdultPersonWithSSN(objectSSN, props.customerId)
    );

    setShowMultipleSsnError([...invalidSSNObjects, ...invalidAgeSSNObjects]);

    if (invalidSSNObjects.length === 0 && invalidAgeSSNObjects.length === 0) {
      return props.onSearchSSN(validSSNObjects);
    }
  };
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleOnSubmit(); // Call the submit handler
    }
  };

  return (
    <Div display="flex" flexDirection="column" gridGap={[3, 3, 2, 2]}>
      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        gridGap={[2, 2, 3, 3]}
        height={!isMultiple ? ["auto", "auto", "40px", "40px"] : "auto"}
      >
        {!isMultiple && (
          <Div
            position="relative"
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            gridGap={[2, 2, 0, 0]}
            width={[1, 1, "543px", "543px"]}
          >
            <RoundedInputText
              width={1}
              height="40px"
              placeholder={messages.placeholder_search}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
              onKeyDown={handleKeyDown}
            />
            <PrimaryButtonIcon
              height="100%"
              position={["static", "static", "absolute", "absolute"]}
              right={0}
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit}
              width={[1, 1, "150px", "150px"]}
            />
          </Div>
        )}

        {isMultiple && (
          <Div
            display="flex"
            flexDirection={"column"}
            alignItems={["stretch", "stretch", "flex-end", "flex-end"]}
            gridGap="14px"
            width={[1, 1, "540px", "540px"]}
          >
            <InputTextArea
              width={1}
              rows={4}
              cols={24}
              placeholder={messages.placeholder_search_multiple}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
              borderRadius={20}
              lineHeight={2}
              backgroundColor="transparent"
            />
            <PrimaryButtonIcon
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit}
            />
          </Div>
        )}

        <Div display="flex" flexDirection="column" gridGap={3}>
          {isMultiple && (
            <Div
              display="flex"
              backgroundColor={"var(--grey-lightest)"}
              alignItems="flex-start"
              borderRadius="10px"
              gridGap="10px"
              px="14px"
              py="9px"
              width={[1, 1, "fit-content", "fit-content"]}
            >
              <Icon name="info" rounded={true} height="24px" width="24px" />

              <Div display="flex" flexDirection="column" gridGap={2}>
                <Text>{messages.up_to_10_ids}</Text>
                <Text>{messages.seperate_id_by_comma}</Text>
              </Div>
            </Div>
          )}

          {isAuthenticated && !isMultiple && (
            <Div
              mt={3}
              display={["flex", "flex", "none", "none"]}
              justifyContent="start"
              alignItems="center"
              height="fit-content"
            >
              <Link onClick={handleMultipleSsnSearch}>
                <Icon
                  mr={1}
                  fontSize="var(--fs-icon-m)"
                  fontWeight="var(--semibold-weight)"
                  name="plus"
                  color="var(--blue-dark) !important"
                />
                <ColouredMediumWeight>
                  {messages.search_several_pin}
                </ColouredMediumWeight>
              </Link>
            </Div>
          )}

          <Div
            mt={[2, 2, 0, 0]}
            px="14px"
            py="9px"
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            alignItems={["flex-start", "flex-start", "center", "center"]}
            backgroundColor={"var(--grey-lightest)"}
            borderRadius="10px"
            gridGap="10px"
          >
            <Div display="flex" gridGap="10px" alignItems="center">
              <Icon name="info" rounded={true} height="24px" width="24px" />

              <Text whiteSpace="nowrap">
                {messages.security_number_details}
              </Text>
            </Div>

            <LinkArrow
              pl={[3, 3, 0, 0]}
              target="_blank"
              direction="right"
              href={`${Config.WP_URL}${ROUTES.INTERNATIONELLBAKGRUNDSANALYS.URL}`}
              label={messages.search_label_click_here}
              variant="secondary"
              fontWeight="var(--medium-weight)"
              flexWrap="nowrap"
              whiteSpace="nowrap"
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Div>
      </Div>

      {showMultipleSsnError.length > 0 && (
        <Div width={1} m={1}>
          <ColouredSemiBoldText color="var(--red)">
            {ssnNumbers.length === 0 ? (
              messages.error_empty_search
            ) : (
              <ul>
                {showMultipleSsnError.map(({ ssn, error }) => (
                  <li key={`${ssn}-${error}`}>{error}</li>
                ))}
              </ul>
            )}
          </ColouredSemiBoldText>
        </Div>
      )}

      {isAuthenticated && !isMultiple && (
        <Div
          mt={2}
          display={["none", "none", "flex", "flex"]}
          justifyContent="start"
          alignItems="center"
          height="fit-content"
        >
          <Link onClick={handleMultipleSsnSearch}>
            <Icon
              mr={1}
              fontSize="var(--fs-icon-m)"
              fontWeight="var(--semibold-weight)"
              name="plus"
              color="var(--blue-dark) !important"
            />
            <ColouredMediumWeight>
              {messages.search_several_pin}
            </ColouredMediumWeight>
          </Link>
        </Div>
      )}
    </Div>
  );
};

SSNSearch.propTypes = {
  ssnNumbers: PropTypes.array,
  customerId: PropTypes.number,
  onSearchSSN: PropTypes.func,
  onChange: PropTypes.func,
};

export default SSNSearch;
