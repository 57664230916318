import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import styled from "styled-components";

import { personInformationActions } from "@app/reducers/personInformationReducer";
import getSubscription from "@app/services/subscription/getSubscription";

import { useUser } from "@contexts/AuthProvider";

import BankIDLogin from "@components/BankidComponent/BankIDLogin";
import Card from "@components/Card";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import { H3 } from "@components/Heading";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import Section from "@components/Section";

import useAuthorization from "@hooks/useAuthorization";

import {
  ROUTES,
  ADMIN_NAV,
  ADMIN_NAV_MY_PROFILE,
  COMPANY,
  CUSTOMER,
} from "@utils/constant";
import { BANK_ID_SCOPES } from "@utils/enum";
import { isAdminUser, isAuthenticated } from "@utils/utils";

import { LoginCredentialsForm, LoginOptionsSeparator } from "./components";

const StyledDiv = styled(Div)`
  @media (min-height: 720px) {
    margin-top: 5rem;
  }
`;

const isTokenValid = token => {
  return token != null;
};

export const Login = () => {
  const { hasAnyPermissions } = useAuthorization();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { userInfo: { type: userType, customer = {} } = {} } = useSelector(
    state => state.authReducer
  );
  const { initializeUser } = useUser();

  const [bankIdLoginError, setBankIdLoginError] = useState("")

  const isLoginErrorDialogVisible = useMemo(() => bankIdLoginError !== "", [bankIdLoginError])

  const findPath = () => {
    const [{ redirectTo: fallbackUrl }] = ADMIN_NAV_MY_PROFILE;
    const { redirectTo: targetRedirectUrl } =
      ADMIN_NAV.find(({ permissions = [] }) =>
        hasAnyPermissions(permissions)
      ) ?? {};

    return targetRedirectUrl ?? fallbackUrl;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectToPage = useCallback(
    async user => {
      const { customer: { id = "" } = {}, type = "" } = user;

      if (type == CUSTOMER && id) {
        const { data: { data: subscriptionData = [] } = {} } =
          await getSubscription(id);
        const [activeSubscription = false] = subscriptionData;

        dispatch(
          personInformationActions.setHasActiveSubscription(activeSubscription)
        );
      }
    },
    [dispatch]
  );

  const handleError = useCallback((error) => {
    if (error === messages.message_bankID_unknown_error) {
      setBankIdLoginError(messages.bank_id_login_error_content);
      return;
    }

    setBankIdLoginError(error);
  }, [messages.bank_id_login_error_content, messages.message_bankID_unknown_error]);

  const handleCloseErrorDialog = useCallback(() => {
    setBankIdLoginError("");
  }, []);

  const handleLoginSubmit = useCallback(
    ({ token, user }) => {
      if (!isTokenValid(token)) {
        handleError(messages.invalid_data);
        return;
      }

      localStorage.setItem("token", token);
      initializeUser(user);
      redirectToPage(user);
    },
    [handleError, initializeUser, messages.invalid_data, redirectToPage]
  );

  if (isAuthenticated()) {
    const redirectTarget = { pathname: ROUTES.USER_PROFILE.URL };

    if (isAdminUser()) {
      redirectTarget.pathname = findPath();
    } else if (userType === CUSTOMER && customer.type === COMPANY) {
      redirectTarget.pathname = ROUTES.SEARCH.URL;
    }

    return <Redirect to={redirectTarget} />;
  }

  return (
    <Section
      p={[3, 3, 4, 4]}
      flex={1}
      display="flex"
      justifyContent="center"
      bg="var(--site-background-color)"
    >
      {isLoginErrorDialogVisible && (
        <ErrorDialog
          title={messages.bank_id_login_error_title}
          errorMessage={bankIdLoginError}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <Div
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <StyledDiv
          display="flex"
          width={1}
          alignItems="center"
          justifyContent="center"
          marginTop="0rem !important"
          mb={4}
        >
          <Card width={1} maxWidth="464px">
            <Div
              px={[3, 3, 4, 4]}
              py="24px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gridGap={3}
            >
              <H3 textTransform="none" color="var(--blue-dark) !important">
                {messages.title_log_in_with_email}
              </H3>

              <LoginCredentialsForm
                onSubmit={handleLoginSubmit}
                onError={handleError}
              />
              <>
                <LoginOptionsSeparator />

                <H3 textTransform="none" color="var(--blue-dark) !important">
                  {messages.title_bankid_login}
                </H3>

                <Div width={1} maxWidth="200px">
                  <BankIDLogin
                    scope={BANK_ID_SCOPES.LOGIN}
                    buttonLabel={messages.login}
                    onSuccess={handleLoginSubmit}
                    onError={handleError}
                  />
                </Div>
              </>
            </Div>
          </Card>
        </StyledDiv>

        <GDPRInformationMessage />
      </Div>
    </Section>
  );
};

Login.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Login;
