import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";

import {
  WRITE,
  ROUTES,
  ADMIN_NEW_WATCHLIST,
  ADMIN_WATCHLIST_URL,
  ADMIN_CUSTOMER_DETAILS,
} from "@utils/constant";

const List = ({ customerId }) => {
  const history = useHistory();
  const { messages } = useIntl();

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const searchableColumns = ["id", "name"];
  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";

  const handleCreateWatchList = () => {
    history.push(
      `${ROUTES.ADMIN_CUSTOMERS.URL}/${customerId}/${ADMIN_NEW_WATCHLIST}`,
    );
  };

  const dataTableConfig = {
    enable_csv_download: true,
    csv_filename: "watchlists.csv",
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_watchlist_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/watch-lists`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["watchListObjects"]],
        ["filter[customer_id]", customerId],
      ],
    },
    header: {
      actions: [
        {
          id: "new-watch-list",
          type: "button",
          label: messages.label_new_watch_list,
          onClick: handleCreateWatchList,
          width: "auto",
          icon: "plus",
          variant: "header",
          height: 40,
          px: 4,
          py: 20,
          isHidden: permissions?.["watch-lists"] !== WRITE,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    columns: [
      {
        title: "label_list",
        width: "200px",
        db_field: "name",
        type: "text",
        sortField: "name",
      },
      {
        title: "title_objects",
        db_field: "watch_list_objects_count",
        type: "text",
      },

      {
        title: "label_created",
        db_field: "created_at",
        type: "dateTime",
      },
      {
        title: "label_last_results",
        db_field: "updated_at",
        type: "dateTime",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) =>
              `${ROUTES.ADMIN_CUSTOMERS.URL}/${customerId}/${ADMIN_WATCHLIST_URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
          },
        ],
      },
    ],

    filters: [
      {
        title: messages.label_status,
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
          },
          {
            label: messages.deleted,
            value: 0,
          },
        ],
      },
      {
        title: "registered_date",
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
  };

  return (
    <Container m={"0px !important"}>
      <DataTable config={dataTableConfig} />
    </Container>
  );
};

List.propTypes = {
  customerId: PropTypes.number,
};

export default List;
