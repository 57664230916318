import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { LinkArrow } from "@components/Link";
import Div from "@components/Div";
import { H1, H3 } from "@components/Heading";
import Icon from "@components/Icon";
import Span from "@components/Span";
import { INVITATION_TAKEN, INVITATION_EXPIRED } from "@utils/constant";

const ERROR_MESSAGES = {
  [INVITATION_TAKEN]: {
    titleKey: "title_invitation_taken",
    messageKey: "message_invitation_taken",
  },
  [INVITATION_EXPIRED]: {
    titleKey: "title_invitation_expired",
    messageKey: "message_invitation_expired",
  },
  default: {
    titleKey: "title_invalid_invitation",
    messageKey: "message_invalid_invitation",
  },
};

const ErrorPage = ({ errorType }) => {
  const { messages } = useIntl();
  const location = useHistory();

  const { titleKey, messageKey } =
    ERROR_MESSAGES[errorType] || ERROR_MESSAGES.default;

  return (
    <Div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      textAlign="Center"
    >
      <Div>
        <H1>{messages[titleKey]}</H1>
      </Div>
      <Div mt={24}>
        <H3 textTransform="none">{messages[messageKey]}</H3>
      </Div>
      <Div mt={24}>
        <LinkArrow
          px={20}
          display={"flex"}
          justifyContent={["center", "center", "center", "start"]}
          alignItems="center"
          handleClick={() => location.push("/")}
        >
          <Span info medium>
            {messages.link_label_report_not_found}
          </Span>
          <Icon name="headerarrowright" ml={2} />
        </LinkArrow>
      </Div>
    </Div>
  );
};

ErrorPage.propTypes = {
  errorType: PropTypes.string,
};

export default ErrorPage;
