import { useIntl } from "react-intl";
const useCourtConfig = () => {
  const { messages } = useIntl();
  return {
        "title": "Add Court",
        "logoPosition": "right",
        "pages": [
          {
            "name": "court",
            "elements": [
              {
                "type": "panel",
                "name": "court_info",
                "title": messages.label_court_info,
                "elements": [
                  {
                    "type": "text",
                    "name": "court_name",
                    "title": messages.label_court_name,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "text",
                    "name": "verifiera_court_name",
                    "startWithNewLine": false,
                    "title": messages.label_court_verifiera,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "text",
                    "name": "court_email",
                    "title": messages.label_court_email,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "dropdown",
                    "name": "type",
                    "startWithNewLine": false,
                    "title": messages.type,
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                      {
                        "value": "district_court",
                        "text": "[[district_court]]"
                      },
                      {
                        "value": "court_of_appeal",
                        "text": "[[court_of_appeal]]"
                      },
                      {
                        "value": "supreme_court",
                        "text": "[[supreme_court]]"
                      },
                      {
                        "value": "administrative_court ",
                        "text": "[[administrative_court]]"
                      },
                      {
                        "value": "administrative_court_of_appeal",
                        "text": "[[administrative_court_of_appeal]]"
                      },
                      {
                        "value": "supreme_administrative_court",
                        "text": "[[supreme_administrative_court]]"
                      },
                      {
                        "value": "labour_court",
                        "text": "[[labour_court]]"
                      }
                    ]
                  },
                  {
                    "type": "custom_tagbox",
                    "name": "secondary_court_email",
                    "title": "[[message_alternate_email_id]]"
                  },
                ]
              }
            ]
          }
        ],
        "showTitle": false
      }
};

export default useCourtConfig;
