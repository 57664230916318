import React from "react";

import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";

import { DataEditorDialog } from "./components";

import { useSourceDataTab } from "./SourceDataTab.hooks";

const SourceDataTab = () => {
  const { config, isLoading, jsonData, handleCloseDataEditorDialog } =
    useSourceDataTab();

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {jsonData && (
        <DataEditorDialog
          data={jsonData}
          onClose={handleCloseDataEditorDialog}
        />
      )}

      <DataTable config={config} />
    </>
  );
};

export default SourceDataTab;
