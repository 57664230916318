import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import {
  PROCESS_TYPE
} from "@utils/enum";
import { formatDataStatus } from "@utils/utils";

import { getConfig } from "./DetailsTab.utils";

export const useDetailsTab = (caseDetails, onCaseDetailsUpdate) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const hasWriteCasesPermission = hasAllPermissions([
    AdminPermissions.AdminWriteCases,
  ]);

  const handleOpenErrorDialog = useCallback(() => {
    showErrorToast(messages.exception_error_message);
  }, [messages, showErrorToast]);

  const formatReport = useCallback(
    data => {
      const { report_id, is_report } = data;
      const reportString = report_id
        ? messages.report_status_completed
        : messages.label_pending;
      const result = String(
        is_report ? reportString : messages.label_no_report
      );

      return result;
    },
    [messages]
  );

  const formatData = useCallback(
    data => {
      const {
        id,
        created_at,
        status,
        source_data,
        user,
        order_user,
        data_sources = [],
        data_sources_status,
        order_item,
        process_type,
        customer_name
      } = data ?? {};

      const formattedDataSources = data_sources.map(value => ({
        name: messages[`datasource_${value}`],
      }));

      const formattedData = {
        id,
        user,
        created_at,
        status: status?.replace("-", "_"),
        data_status: formatDataStatus(source_data),
        data_sources: formattedDataSources,
        data_sources_status,
        report_status: formatReport(data),
        order_item,
        order_user,
        process_type,
        customer_name
      };

      return formattedData;
    },
    [formatReport, messages]
  );

  const handleUpdateCase = async values => {
    try {
      setIsLoading(true);

      const payload = Object.entries(values).reduce((acc, [key, value]) => {
        if (value !== caseDetails[key]) {
          acc[key] = value;
        }

        return acc;
      }, {});

      const { data: updatedCaseDetailsData } = await updateCaseDetailsById(
        case_id,
        payload
      );

      onCaseDetailsUpdate(updatedCaseDetailsData);
    } catch (error) {
      handleOpenErrorDialog();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAssignee = event => {
    const { value } = event;
    const payload = {
      user: value,
      user_id: value.id,
    };

    handleUpdateCase(payload);
  };

  const config = useMemo(
    () => getConfig(formatData(caseDetails)),
    [formatData, caseDetails]
  );

  const shouldShowAssigneeCard = useMemo(() => {
    const { process_type = "" } = caseDetails ?? {};

    return process_type === PROCESS_TYPE.MANUAL && hasWriteCasesPermission;
  }, [caseDetails, hasWriteCasesPermission]);

  return {
    config,
    isLoading,
    shouldShowAssigneeCard,
    handleOpenErrorDialog,
    handleUpdateAssignee,
    handleUpdateCase
  };
}
