import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { Error } from "@components/Heading";
import ComponentDropdown from "@components/Dropdown";
import Icon from "@components/Icon";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";

const Dropdown = ({ name, placeholder, options }) => {

  const { messages } = useIntl();
  const { values, handleChange, errors, touched } = useFormikContext();
  const formattedOptions = options.map(option => ({
    label: option?.label?.props?.id && messages[option?.label?.props?.id] || option.label,
    value: option.value ,
  }));
  return (
    <Div flex="1 1 40%" maxWidth="526px">
      <ComponentDropdown
        width={1}
        mt={2}
        name={name}
        value={values?.[name]}
        onChange={handleChange}
        options={formattedOptions}
        placeholder={placeholder}
        dropdownIcon={<Icon name="chevrondown" />}
      />
      {errors[name] && touched[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors[name]}</Error>
        </Div>
      )}
    </Div>
  );
};
Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
};
export default Dropdown;
