import React, { useState, useEffect } from "react";

import verifyRegistrationService from "@app/services/auth/verifyRegistrationService";
import userValidation from "@app/services/users/userValidation";

import { USER_REGISTRATION_COMPLETED } from "@utils/constant";
import useHistory from "@utils/useHistory";
import queryString from "query-string";

import CompleteRegistration from "../auth/shared/CompleteRegistration";

const UserCompleteRegistration = () => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const { search = "" } = location;
  const params = queryString.parse(search);
  const { token = "" } = params;

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const { data } = await userValidation({ token });
        setUser(data);
      } catch (error) {
        setValidationError(
          error?.response?.data?.message || "Validation failed"
        );
      } finally {
        localStorage.removeItem("token", token);
        setLoading(false);
      }
    };

    validateToken();
  }, [token]);

  const onClick = async data => {
    localStorage.setItem("token", token);
    setLoading(true);

    try {
      await verifyRegistrationService(token, data);

      history.push(USER_REGISTRATION_COMPLETED);
    } catch (error) {
      setError(error.message);
    } finally {
      localStorage.removeItem("token", token);
      setLoading(false);
    }
  };

  return (
    <CompleteRegistration
      onClick={onClick}
      error={error}
      validationError={validationError}
      loading={loading}
      user={user}
    />
  );
};

export default UserCompleteRegistration;
