import React from "react";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import PromptDialog from "@components/PromptDialog";

import {
  ReportFormComponent,
  ReportPreviewDialog,
  ReportStateMessageComponent,
} from "./components";
import { useReportTab } from "./ReportTab.hooks";

const ReportTab = () => {
  const { messages } = useIntl();
  const {
    case_id,
    isLoading,
    isFullScreenDialogVisible,
    isPreviewDialogVisible,
    isReportCreated,
    isSurveyEditable,
    processType,
    questionDialogContentType,
    reportData,
    reportConfig,
    reportId,
    shouldShowMessage,
    sourceDataStatus,
    surveyMode,
    handleClosePreviewDialog,
    handleCloseQuestionDialog,
    handleCompleteSurvey,
    handleCreateReport,
    toggleFullScreenVisibility,
    handleLoadSurveyRef,
    handleOpenPreviewDialog,
    handleReCreateReport,
    handleSave,
    handleUpdatePreview,
  } = useReportTab();

  const renderReportForm = () => (
    <ReportFormComponent
      isLoading={isLoading}
      isReportCreated={isReportCreated}
      isSurveyEditable={isSurveyEditable}
      surveyMode={surveyMode}
      reportData={reportData}
      reportConfig={reportConfig}
      onToggleFullScreenMode={toggleFullScreenVisibility}
      onComplete={handleCompleteSurvey}
      onRefLoaded={handleLoadSurveyRef}
      onCreateReport={handleCreateReport}
      onSave={handleSave}
      onOpenPreviewDialog={handleOpenPreviewDialog}
      onUpdatePreview={handleUpdatePreview}
    />
  );

  const renderFullScreenDialog = () => {
    return (
      <FullSizeDialog
        fullWidth
        title={`${messages.label_case} #${case_id}: ${messages.label_report}`}
        onClose={toggleFullScreenVisibility}
      >
        <Div className="full-screen-preview">{renderReportForm()}</Div>
      </FullSizeDialog>
    );
  };

  return (
    <Div className="report-config">
      {isLoading && <ProgressSpinner />}

      {questionDialogContentType && (
        <PromptDialog
          title={questionDialogContentType?.title}
          message={questionDialogContentType?.message}
          onConfirm={questionDialogContentType?.onConfirm}
          onCancel={handleCloseQuestionDialog}
          confirmLabel={messages.label_create}
        />
      )}

      {shouldShowMessage && (
        <ReportStateMessageComponent
          isReportCreated={isReportCreated}
          reportId={reportId}
          processType={processType}
          sourceDataStatus={sourceDataStatus}
          onRecreateReport={handleReCreateReport}
        />
      )}

      {isFullScreenDialogVisible && renderFullScreenDialog()}

      {isPreviewDialogVisible && (
        <ReportPreviewDialog
          reportConfig={JSON.parse(reportConfig)}
          reportData={reportData}
          onClose={handleClosePreviewDialog}
        />
      )}

      {renderReportForm()}
    </Div>
  );
};

export default ReportTab;
