import { useEffect, useState } from "react";

import userService from "@app/services/users/userService";

export const useAssigneeCard = (selectedAssignee, onUpdateAssignee, onError) => {
  const [isAssigneeDialogVisible, setIsAssigneeDialogVisible] = useState(false);
    const [assignees, setAssignees] = useState([]);
    const [assigneeValue, setAssigneeValue] = useState(selectedAssignee);
  
    useEffect(() => {
      const fetchAssignees = async () => {
        try {
          const { data: { data: assigneesData = [] } = {} } = await userService(
            "filter[type]=admin&per_page=100"
          );
          const filteredAssignees = assigneesData.filter(assignee =>
            assignee.name?.trim()
          );
  
          setAssignees(filteredAssignees);
        } catch (error) {
          onError?.();
        }
      };
  
      fetchAssignees();
    }, [onError]);
  
    const handleOpenAssigneeDialog = () => {
      setIsAssigneeDialogVisible(true);
    };
  
    const handleCloseAssigneeDialog = () => {
      setIsAssigneeDialogVisible(false);
    };
  
    const handleChangeAssignee = event => {
      const { value } = event;
  
      setAssigneeValue(value);
    };
  
    const handleUpdateAssignee = () => {
      const event = {
        value: assigneeValue,
      };
  
      onUpdateAssignee(event);
      handleCloseAssigneeDialog();
    };

  return {
    assignees,
    assigneeValue,
    isAssigneeDialogVisible,
    handleChangeAssignee,
    handleCloseAssigneeDialog,
    handleOpenAssigneeDialog,
    handleUpdateAssignee,
  };
}
