import React from "react";

import DataTable from "@components/DataTableV2/DataTable";
import TabHeader from "@components/TabHeader";

import ActivityLogDetails from "../../../activityLogs/Details";
import { useActivityLogsTab } from "./ActivityLogsTab.hooks";

const ActivityLogsTab = () => {
  const {
    dataTableConfig,
    selectedActivity,
    showActivityLog,
    tabHeader,
    handleActivityLogDetails,
  } = useActivityLogsTab();

  return (
    <>
      <TabHeader {...tabHeader} />

      <DataTable config={dataTableConfig} />

      {showActivityLog && (
        <ActivityLogDetails
          data={selectedActivity}
          handleClose={handleActivityLogDetails}
        />
      )}
    </>
  );
};

export default ActivityLogsTab;
