import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { PrimaryButton } from "@components/Button";
import Card from "@components/Card";
import Container from "@components/Container";
import Currency from "@components/Currency";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import { H4 } from "@components/Heading";
import { Text } from "@components/Text";

import { SUBSCRIPTION_STATUS } from "@utils/constant";
import { FORM_INPUT_TYPE } from "@utils/enum";

import BillingInformation from "@pages/shared/CreateOrderWizard/orderSummary/BillingInformation";

const getPackageConfig = (data, packages) => {
  const packageOptions = packages.map(o => ({
    label: o?.name,
    value: o?.id,
  }));
  const configuration = {
    packages: [
      {
        key: "product_id",
        translationKey: "package_label",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: Number(data?.product_id),
        fullWidth: true,
      },
      {
        key: "status",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="active" />,
            value: SUBSCRIPTION_STATUS.ACTIVE,
          },
          {
            label: <FormattedMessage id="terminated_label" />,
            value: SUBSCRIPTION_STATUS.TERMINATED,
          },
        ],
        selectorField: "label",
        value: data.status,
        fullWidth: true,
      },
      {
        key: "credits",
        translationKey: "total_credits",
        type: FORM_INPUT_TYPE.TEXT,
        value: `${data.available_credits}/${data.total_credits}`,
      },
      {
        key: "price_in_currency",
        translationKey: "watchlist_label_price",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: <Currency value={data?.price} />,
        fullWidth: true,
      },
    ],
  };
  return configuration;
};

const ActiveSubscription = ({
  activePackage,
  subscriptionPackages = [],
  onCreateSubscription,
  onUpdateSubscription,
}) => {
  const { messages } = useIntl();
  const { customer: { id: customerId = "" } = {} } = useSelector(
    state => state.authReducer.userInfo
  );

  const [selectedBillingAccountId, setSelectedBillingAccountId] = useState("");

  const { packages } = getPackageConfig(activePackage, subscriptionPackages);

  const handleUpdateBillingAccount = async billingAccountId => {
    setSelectedBillingAccountId(billingAccountId);
    onUpdateSubscription({ billingAccountId });
  };

  const handleUpdateSubscription = () => {
    const payload = {
      billing_account_id: selectedBillingAccountId,
    };

    onCreateSubscription(payload);
  };

  return (
    <Container m={"0px !important"} maxWidth={"1110px !important"} pt={1}>
      <EditableFieldsCard
        title={messages.package_label}
        config={packages}
        data={activePackage}
        isEditPermission={false}
      />

      <Div>
        <BillingInformation
          customerId={customerId}
          initialBillingAccountId={activePackage?.billing_account_id}
          onSelectBillingAccount={handleUpdateBillingAccount}
        />
      </Div>

      <Card my={3} p={3} borderRadius="20px" width={1}>
        <Div
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={["column", "column", "column", "row"]}
        >
          <Div mb={[3, 3, 3, 0]}>
            <H4 mb={1}>{messages.title_change_subscription}</H4>
            <Text>{messages.description_change_subscription}</Text>
          </Div>
          <PrimaryButton
            width={[1, 1, 1, "150px"]}
            label={messages.contact}
            onClick={handleUpdateSubscription}
          />
        </Div>
      </Card>
    </Container>
  );
};

ActiveSubscription.propTypes = {
  activePackage: PropTypes.object,
  subscriptionPackages: PropTypes.array,
  onCreateSubscription: PropTypes.func,
  onUpdateSubscription: PropTypes.func,
};

export default ActiveSubscription;
