import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Dropdown from "@components/Dropdown";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { PrimaryButtonOutlined, PrimaryButton } from "@components/Button";
import { Text, TextMediumWeight } from "@components/Text";

const SetAssigneeDialog = ({
  selectedAssignee,
  availableAssignees,
  onSetAssignee,
  onHide,
  onSubmitAssignee,
}) => {
  const { messages } = useIntl();

  const dialogHeader = () => (
    <Div
      width={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <H3 textTransform="none">{messages.title_assigned_person}</H3>
      <Text mt={3}>{messages.text_chose_assignee}</Text>
    </Div>
  );

  const renderDropdownIcon = () => <Icon name="chevrondown" />;

  const renderValue = () => (
    <Text>{selectedAssignee || `${messages.placeholder_choose}...`}</Text>
  );

  const renderItem = item => <TextMediumWeight>{item.label}</TextMediumWeight>;

  return (
    <Dialog
      header={dialogHeader}
      visible="displayBasic"
      draggable={false}
      onHide={onHide}
      width={[1, 450]}
      m={[3, 3, 3, "auto"]}
    >
      <Div pt={4}>
        <TextMediumWeight>{messages.label_assignee}</TextMediumWeight>
        <Dropdown
          mt={2}
          filter
          value={selectedAssignee}
          onChange={onSetAssignee}
          options={availableAssignees}
          optionLabel="label"
          placeholder={`${messages.placeholder_choose}...`}
          width={1}
          dropdownIcon={renderDropdownIcon}
          valueTemplate={renderValue}
          itemTemplate={renderItem}
        />
      </Div>
      <Div
        mt={3}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        justifyContent="center"
        gridGap={3}
      >
        <PrimaryButton
          rounded
          label={messages.label_save}
          onClick={onSubmitAssignee}
          width={[1, 1, "150px", "150px"]}
          height="40px"
        />
        <PrimaryButtonOutlined
          rounded
          label={messages.label_cancel}
          onClick={onHide}
          width={[1, 1, "150px", "150px"]}
          height="40px"
        />
      </Div>
    </Dialog>
  );
};

SetAssigneeDialog.propTypes = {
  selectedAssignee: PropTypes.string,
  availableAssignees: PropTypes.array,
  onSetAssignee: PropTypes.func,
  onHide: PropTypes.func,
  onSubmitAssignee: PropTypes.func,
};

export default SetAssigneeDialog;
