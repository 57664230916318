import React from "react";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import { H4, H5 } from "@components/Heading";
import { PrimaryButtonIconOutlined } from "@components/Button";
import Icon from "@components/Icon";
import Editor from "@components/Editor";
import ProgressSpinner from "@components/ProgressSpinner";

import Card from "@components/Card";
import Comment from "@components/Comment";

import { useCommentsTab } from "./CommentsTab.hooks";

const CommentsTab = () => {
  const {
    comments,
    isFetching,
    newComment,
    handleAddComment,
    handleDeleteComment,
    handleOnChangeComment,
  } = useCommentsTab();
  const { messages } = useIntl();

  return (
    <Div>
      {isFetching && <ProgressSpinner />}

      {!isFetching && (
        <Card p={3} width={[1, 1, 8 / 10]}>
          <Div
            pb={10}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid var(--grey-lightest)"
          >
            <H4>{messages.label_comments}</H4>
            <H5>{messages.label_internal_storge}</H5>
          </Div>
          {comments?.map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              onDeleteComment={handleDeleteComment}
            />
          ))}
          <Div mt={comments?.length ? 4 : 3}>
            <TextMediumWeight>{messages.label_add_comment}</TextMediumWeight>
            <Editor
              width={1}
              my={2}
              value={newComment}
              onTextChange={handleOnChangeComment}
            />
          </Div>
          <Div width={1} display="flex" justifyContent="flex-end">
            <Div mt={60}>
              <PrimaryButtonIconOutlined
                rounded
                height={40}
                px={22}
                icon={
                  <Icon
                    name="add-comment"
                    mr={1}
                    fontSize={"var(--fs-icon-m)"}
                    fontWeight={"var(--medium-weight)"}
                  />
                }
                label={messages.label_add_comment}
                onClick={handleAddComment}
                alignItems={"end"}
              />
            </Div>
          </Div>
        </Card>
      )}
    </Div>
  );
};

export default CommentsTab;
