import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import createCasesReport from "@app/services/cases/createCasesReport";
import deleteCasesReportById from "@app/services/cases/deleteCasesReportById";
import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { CASES_DATA_STATUS_TYPE, SURVEY_MODE_TYPE } from "@utils/enum";

import {
  QUESTION_DIALOG_CONTENT_TYPE,
  QUESTION_DIALOG_TYPE,
} from "./ReportTab.constants";

export const useReportTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [questionDialogContentType, setQuestionDialogContentType] = useState(
    QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
  );
  const [caseDetails, setCaseDetails] = useState({});
  const [isFullScreenDialogVisible, setIsFullScreenDialogVisible] =
    useState(false);
  const [isPreviewDialogVisible, setIsPreviewDialogVisible] = useState(false);

  const surveyRef = useRef(null);

  const hasWriteReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminWriteReportAnalysis,
  ]);

  const {
    report_data: reportData,
    report_config: reportConfig,
    process_type: processType,
    data_sources_status: sourceDataStatus,
    report_id: reportId,
  } = caseDetails;

  const isReportCreated = reportId !== null;
  const isSurveyEditable =
    sourceDataStatus === CASES_DATA_STATUS_TYPE.COMPLETED &&
    !isReportCreated &&
    hasWriteReportAnalysisPermission;
  const shouldShowMessage =
    !isLoading &&
    (isReportCreated || sourceDataStatus !== CASES_DATA_STATUS_TYPE.COMPLETED);
  const surveyMode = isSurveyEditable
    ? SURVEY_MODE_TYPE.EDIT
    : SURVEY_MODE_TYPE.DISPLAY;

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        setIsLoading(true);

        const { data: caseData = {} } = await getCaseDetailsById(case_id);

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseDetails();
  }, [case_id, messages, showErrorToast]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          report_data: data,
        };

        const { data: caseData = {} } = await updateCaseDetailsById(
          case_id,
          payload
        );

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [case_id, messages, showErrorToast]
  );

  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  const handleCreateReport = () => {
    if (!surveyRef.current) {
      return;
    }

    const survey = surveyRef.current;
    const validationResult = survey.validate();

    if (!validationResult || survey.hasErrors()) {
      return;
    }

    const createType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.CREATE];
    createType.message = (
      <FormattedMessage
        id="message_create_report"
        defaultMessage="Are you sure you want to create a report for {productName}?"
        values={{
          productName: caseDetails?.product?.name,
        }}
      />
    );
    createType.onConfirm = handleConfirmCreateReport;

    setQuestionDialogContentType(createType);

    handleSave();
  };

  const handleConfirmCreateReport = () => {
    createReport();
  };

  const handleReCreateReport = () => {
    const reCreateType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.RE_CREATE];
    reCreateType.onConfirm = handleConfirmReCreateReport;

    setQuestionDialogContentType(reCreateType);
  };

  const createReport = async () => {
    try {
      setIsLoading(true);

      const { id: generatedReportId = "" } = await createCasesReport({
        case_id: case_id,
      });

      setCaseDetails({ ...caseDetails, report_id: generatedReportId });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const recreateReport = async () => {
    try {
      setIsLoading(true);

      await deleteCasesReportById(reportId);

      setCaseDetails({ ...caseDetails, report_id: null });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const handleConfirmReCreateReport = () => {
    recreateReport();
  };

  const handleCloseQuestionDialog = () => {
    setQuestionDialogContentType(
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
    );
  };

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const toggleFullScreenVisibility = () => {
    setIsFullScreenDialogVisible(prevValue => !prevValue);
  };

  const handleOpenPreviewDialog = () => {
    handleSave();
    setIsPreviewDialogVisible(true);
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewDialogVisible(false);
  };

  const handleUpdatePreview = ({ name, value }) => {
    const updatedData = { ...reportData, [name]: value };

    setCaseDetails(prevValue => ({
      ...prevValue,
      report_data: updatedData,
    }));
  };

  return {
    case_id,
    isLoading,
    isFullScreenDialogVisible,
    isPreviewDialogVisible,
    isReportCreated,
    isSurveyEditable,
    processType,
    questionDialogContentType,
    reportData,
    reportConfig,
    reportId,
    shouldShowMessage,
    sourceDataStatus,
    surveyMode,
    handleClosePreviewDialog,
    handleCloseQuestionDialog,
    handleCompleteSurvey,
    handleCreateReport,
    toggleFullScreenVisibility,
    handleLoadSurveyRef,
    handleOpenPreviewDialog,
    handleReCreateReport,
    handleSave,
    handleUpdatePreview,
  };
};
