import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import createSubscriptionRequest from "@app/services/subscription/createSubscriptionRequest";
import updateSubscription from "@app/services/subscription/updateSubscription";

import { useToast } from "@hooks/useToast";

import { loadActiveSubscriptionData, loadServicesData } from "@utils/common";

import { getSubscriptionConfig } from "./CustomerActiveSubscriptionTab.utils";

const useCustomerActiveSubscription = () => {
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { showErrorToast, showSuccessToast } = useToast();

  const [isFetching, setIsFetching] = useState(false);
  const [activePackage, setActivePackage] = useState(null);
  const [productId, setProductId] = useState("");
  const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] =
    useState(false);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);

  const subscriptionStatus = useMemo(
    () => activePackage?.status ?? null,
    [activePackage?.status]
  );
  const activityLog = useMemo(
    () => activePackage?.orders ?? [],
    [activePackage?.orders]
  );

  const activePackageRef = useRef(activePackage);

  const user = {
    name: userInfo?.name,
    email: userInfo?.email,
    phone: userInfo?.phone,
  };

  const { details: createSubscriptionConfig } = getSubscriptionConfig(
    { ...activePackage, ...user },
    subscriptionPackages,
    productId
  );

  useEffect(() => {
    const fetchPackages = async () => {
      const { customer: { id: customerId } = {} } = userInfo;
      const packagesList = await loadServicesData(customerId);

      setSubscriptionPackages(packagesList);
    };

    const fetchActiveSubscription = async () => {
      const currentPackage = (await loadActiveSubscriptionData(userInfo)) ?? {};
      const { product_id } = currentPackage;

      activePackageRef.current = currentPackage;

      setActivePackage(currentPackage);
      setProductId(product_id);
    };

    try {
      setIsFetching(true);

      fetchPackages();

      if (activePackage) {
        return;
      }

      fetchActiveSubscription();
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsFetching(false);
    }
  }, [
    activePackage,
    messages.exception_error_message,
    showErrorToast,
    userInfo,
  ]);

  const handleShowCreateSubscriptionModal = useCallback((id = "") => {
    if (id) {
      setProductId(id);
    }

    setShowCreateSubscriptionModal(prevValue => !prevValue);
  }, []);

  const handleCreateSubscriptionRequest = async values => {
    setIsFetching(true);
    try {
      await createSubscriptionRequest(values);
      setActivePackage(false);
      activePackageRef.current = {};
      showSuccessToast(messages.message_subscription_request_created);
    } catch (e) {
      setIsFetching(false);
      showErrorToast(messages.error_try_later);
    }
  };

  const handleUpdateSubscription = useCallback(
    async values => {
      try {
        const { billingAccountId } = values;
        const payload = {
          billing_account_id: billingAccountId,
        };

        await updateSubscription(payload, activePackageRef.current?.id);

        setActivePackage(prevValue => ({
          ...prevValue,
          billing_account_id: billingAccountId,
        }));

        activePackageRef.current = {
          ...activePackageRef.current,
          billing_account_id: billingAccountId,
        };
      } catch (error) {
        showErrorToast(messages.error_try_later);
      }
    },
    [messages.error_try_later, showErrorToast]
  );
  return {
    activityLog,
    subscriptionPackages,
    createSubscriptionConfig,
    isFetching,
    showCreateSubscriptionModal,
    subscriptionStatus,
    activePackage,
    handleShowCreateSubscriptionModal,
    handleCreateSubscriptionRequest,
    handleUpdateSubscription,
  };
};

export default useCustomerActiveSubscription;
