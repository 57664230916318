import React from "react";
import Creatable from "react-select/creatable";
import { SurveyQuestionTagbox, ReactQuestionFactory } from "survey-react-ui";
import {
  ElementFactory,
  QuestionTagboxModel,
  Serializer,
  RendererFactory,
  ItemValue,
} from "survey-core";

const CUSTOM_TAGBOX_TYPE = "custom_tagbox";

export class CustomTagboxModel extends QuestionTagboxModel {
  getType() {
    return CUSTOM_TAGBOX_TYPE;
  }
}

function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
}

export class SurveyQuestionCustomTagbox extends SurveyQuestionTagbox {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onCreateOption = this.onCreateOption.bind(this);
  }

  get question() {
    return this.questionBase;
  }

  get options() {
    return this.question.visibleChoices.map(c => ({
      value: c.value,
      label: c.value,
    }));
  }

  get selectedOptions() {
    return Array.isArray(this.question.value)
      ? this.question.value.map(
          val =>
            this.options.find(o => o.value === val) || {
              value: val,
              label: val,
            }
        )
      : [];
  }

  onSelectChange(selectedOptions) {
    if (this.question.errors && this.question.errors.length) {
      this.question.clearErrors();
    }
    const values = selectedOptions ? selectedOptions.map(o => o.value) : [];
    this.question.value = values;
  }

  onCreateOption(inputValue) {
    if (!inputValue.trim() || !this.question) return;

    if (!isValidEmail(inputValue)) {
      this.question.hasErrors(true);
      this.question.errors.push("Please enter a valid email address.");
      this.question.onErrorsChanged();
      return;
    }
    const question = this.question;

    const inputValueExists = question.choices.some(
      choice => choice.value === inputValue
    );
    if (!inputValueExists) {
      const newItem = new ItemValue(inputValue, inputValue);
      this.question.choices.push(newItem);
    }
    const newValues = [...(this.question.value || []), inputValue];
    this.setValueCore(newValues);
  }

  renderElement() {
    return (
      <Creatable
        id={this.question.inputId}
        value={this.selectedOptions}
        onChange={this.onSelectChange}
        onCreateOption={this.onCreateOption}
        options={this.options}
        required={this.question.isRequired}
        menuPortalTarget={document.body}
        isMulti={true}
        className="custom-tagbox"
        placeholder="Skriv in epost-adress här för att lägga till ny"
      />
    );
  }
}

function applyRendererRegistration() {
  RendererFactory.Instance.registerRenderer(
    CUSTOM_TAGBOX_TYPE,
    CUSTOM_TAGBOX_TYPE,
    props => React.createElement(SurveyQuestionCustomTagbox, props)
  );
}

function applyQuestionRegistration() {
  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TAGBOX_TYPE, props => {
    return React.createElement(SurveyQuestionCustomTagbox, props);
  });
}

function applySerializerConfig() {
  Serializer.addClass(
    CUSTOM_TAGBOX_TYPE,
    [],
    () => new CustomTagboxModel(""),
    "tagbox"
  );
}

export function registerCustomTagbox() {
  ElementFactory.Instance.registerElement(CUSTOM_TAGBOX_TYPE, name => {
    return new CustomTagboxModel(name);
  });

  applyQuestionRegistration();
  applyRendererRegistration();
  applySerializerConfig();
}

registerCustomTagbox();

export { SurveyQuestionCustomTagbox as CustomTagbox };
